<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h2>Result</h2>
    </div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-text-field
          v-model="name"
          :rules="requiredRules"
          label="Name"
          required
      ></v-text-field>
      <v-textarea
          label="Description"
          :rules="requiredRules"
          v-model="description"
      ></v-textarea>
      <div>
        <label class="v-label theme--light">Test</label>
        <v-select
            :options="tests"
            :reduce="item => item._id"
            v-model="test"
            :getOptionLabel="item => item.name"
        ></v-select>
      </div>
      <div style="margin-top: 10px">
        <div>Range</div>
        <div style="display: flex; align-items: center">
          <div style="padding-right: 5px;">{{ range[0] || range[0] === 0 ? range[0] : '' }}</div>
          <v-range-slider
              :disabled="!test"
              :thumb-label="true"
              :ticks="true"
              thumb-size="24"
              v-model="range"
              :max="99"
              :min="0"
          ></v-range-slider>
          <div style="padding-left: 5px;">{{ range[1] || range[1] === 0 ? range[1] : '' }}</div>
        </div>
      </div>
      <div>
        <label class="v-label theme--light">Color</label>
        <v-text-field v-model="color" hide-details class="ma-0 pa-0" solo>
          <template v-slot:append>
            <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div :style="swatchStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="color" flat />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </div>

      <div>
        <v-file-input
            show-size
            label="Image"
            truncate-length="30"
            @change="onFileChange"
        ></v-file-input>
        <img
            v-if="imagePreviewURL"
            :src="imagePreviewURL"
            alt=""
            style="max-width: 100%;width: 250px; object-fit: cover"
        />
      </div>
      <v-btn
          :disabled="!valid"
          :loading="isLoading"
          color="success"
          class="mr-4"
          @click="submit"
      >
        Submit
      </v-btn>
    </v-form>
  </div>
</template>


<script>
  import vSelect from 'vue-select'
  export default {
    name: 'Result',
    components: {
      vSelect
    },
    data() {
      return {
        isLoading: false,
        valid: true,
        name: '',
        description: '',
        test: '',
        tests: [],
        initRange: [],
        range: [0, 2],
        image: '',
        testData: {},
        imagePreviewURL: '',
        color: '#1976D2FF',
        mask: '!#XXXXXXXX',
        menu: false,
        menu2: false,
        requiredRules: [
          v => !!v || 'Field is required',
        ],
      }
    },
    mounted() {
      this.getTests();
      this.getResult();
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          if (this.test) {
            let hasRangeError = false;
            let msg = "Range can't be in ";
            for (let k in this.testData[this.test]) {
              if ((this.testData[this.test][k].min <= this.range[0] && this.testData[this.test][k].max >= this.range[0] ||
                this.testData[this.test][k].min >= this.range[0] && this.testData[this.test][k].min <= this.range[1] && this.testData[this.test][k].max >= this.range[1] ||
                this.testData[this.test][k].min >= this.range[0] && this.testData[this.test][k].max <= this.range[1] ||
                this.range[0] === this.range[1]) && (this.range[0] !== this.initRange[0] && this.range[1] !== this.initRange[1])
              ) {
                hasRangeError = true;
              }
              if (k != 0 && k !== this.testData[this.test].length) {
                msg += ', '
              }
              msg += this.testData[this.test][k].min + '/' + this.testData[this.test][k].max;

            }
            if (!hasRangeError) {
              this.isLoading = true;
              let url = '/results'
              let id = this.$route.params.id && this.$route.params.id !== 'create' ? this.$route.params.id : null;
              if (id) {
                url += '/' + id;
              }
              let data = {
                title: this.name,
                description: this.description,
                test: this.test,
                min: this.range[0],
                max: this.range[1],
                color: this.color,
                created_date: this.created_date,
              }
              if (this.image) {
                data.image = this.image;
              }
              this.$axios[id ? 'put' : 'post'](url, data).then((res) => {
                this.$root.notify({color: 'success', text: 'Success created'})

                this.$router.push("/results")
              }).catch(err => {
                this.isLoading = false;
                this.$root.notify({color: 'error', text: err.error ? err.error : "Unknown Error!"})
              });
            } else {
              this.$root.notify({color: 'error', text: msg + ' in ranges'})
            }
          } else {
            this.$root.notify({color: 'error', text: 'Test is required'})
          }
        } else {
          this.$root.notify({color: 'error', text: 'Fill all fields'})
        }
      },
      onFileChange(payload) {
        const file = payload;
        this.image = file;
        if (file) {
          this.imagePreviewURL = URL.createObjectURL(file);
          URL.revokeObjectURL(file);
          this.uploadFile(file)
        } else {
          this.imagePreviewURL =  null
        }
      },
      uploadFile(f) {
        let formData = new FormData();
        formData.append("files", f);

        return this.$axios.post('/' + this.$store.state.filesUrl, formData).then(res => {
          this.image = res.data[0]._id;
        }).catch(err => {
          this.$root.notify({color: 'error', text: `${f.name} upload failed`})
        })
      },
      getResult() {
        let id = this.$route.params.id;
        if (id && id !== 'create') {
          let url = '/results/' + id;
          this.$axios.get(url).then(res => {
            this.name = res.data.title;
            this.description = res.data.description;
            this.test = res.data.test._id ? res.data.test._id : res.data.test;
            if (res.data.min || res.data.min === 0 && res.data.max) {
              this.range = [res.data.min, res.data.max];
              this.initRange = [res.data.min, res.data.max];
            }
            this.color = res.data.color;
            if (res.data.image) {
              this.imagePreviewURL = this.$store.state.appUrl + res.data.image.url;
            }
          }).catch(e => {
            console.log(e)
          });
        }
      },
      getTests() {
        let url = '/tests';

        this.$axios.get(url).then(res => {
          this.tests = res.data;
          let testData = {};
          for (let k in this.tests) {
            let t = this.tests[k];
            if (!testData[t._id]) {
              testData[t._id] = [];
            }
            if (t.results && t.results.length) {
              for (let k2 in t.results) {
                if (t.results[k2].min || t.results[k2].min === 0 && t.results[k2].max || t.results[k2].max === 0) {
                  testData[t._id].push({
                    min: t.results[k2].min,
                    max: t.results[k2].max
                  })
                }
              }
            }
          }
          this.testData = testData;
        }).catch(e => {
          console.log(e)
        })
      },
    },
    watch: {

    },
    computed: {
      swatchStyle() {
        const { color, menu } = this
        return {
          backgroundColor: color,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      }
    }
  }

</script>

